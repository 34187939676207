import React, { useContext, useState } from "react"
import { HomeHero } from "../components/Home-Hero"
import { NurturePanel } from "../components/panels/Nurture-Panel"
import { WhoWeAre } from "../components/Who-We-Are"
import { BlogPanel } from "../components/Blog-Panel"
import { WhatWeveDone } from "../components/What-We've-Done"
import { JustALabel } from "../components/Just-A-Label"
import { ImageCarousel } from "../components/Image-Carousel"
import { graphql } from "gatsby"
import { DonatePanel } from "../components/panels/Donate-Panel"
import { GivingLegacy } from "../components/Giving-Legacy.js"
import { BecomeOneOfUs } from "../components/Become-One-Of-Us"
import { Seo } from "../components/Seo"
import { DefaultLayout } from "../layout/DefaultLayout"
import { siteContext } from "../layout/SiteContextProvider"
import { HomeModal } from "../components/Home-Modal"
import { modalText } from "../components/modalText"
import { AdPanel } from "../components/panels/Ad-Panel"
import { AdPanelNoSlide } from "../components/panels/Ad-Panel-No-Slide"

const Index = ({ data }) => {
  const [hovered, setHovered] = useState(false)
  const { toolTipMobile, setToolTipMobile } = useContext(siteContext)

  const expiration_normalizer = items => {
    let ads_array = []

    items.forEach(item => {
      if (
        !item.node.frontmatter.expiration_date ||
        item.node.frontmatter.expiration_date == ""
      ) {
        ads_array.push(item)
      } else {
        let expirationDate = new Date(item.node.frontmatter.expiration_date)
        let currentDate = new Date()
        if (expirationDate > currentDate) {
          ads_array.push(item)
        }
      }
    })
    return ads_array
  }

  const non_expired_ads = expiration_normalizer(data.ads.edges)

  const non_expired_popup = expiration_normalizer(data.popup_banner.edges)
  console.log(non_expired_popup)

  return (
    <>
      <Seo
        title="MLIFE - We are home. Whomever you are."
        description="We are an all-embracing, intergenerational, and intersectional faith-based congregation. Grassroots-led and community-driven."
      />
      <HomeHero />
      <NurturePanel
        toolTipMobile={toolTipMobile}
        setToolTipMobile={setToolTipMobile}
        hovered={hovered}
        setHovered={setHovered}
      />
      {/* this means do we have a pop up and is it featured */}
      {non_expired_popup.length >= 1 && (
        <HomeModal modalContent={non_expired_popup[0].node.frontmatter}>
          Get the details
        </HomeModal>
      )}
      <WhoWeAre />
      <ImageCarousel />
      <BlogPanel blog={data.blog} />
      {non_expired_ads.length > 1 ? (
        <AdPanel home ads={non_expired_ads} />
      ) : (
        <AdPanelNoSlide home ads={non_expired_ads} />
      )}
      <WhatWeveDone />
      <JustALabel
        toolTipMobile={toolTipMobile}
        setToolTipMobile={setToolTipMobile}
        hovered={hovered}
        setHovered={setHovered}
      />
      <DonatePanel />
      <GivingLegacy />
      <BecomeOneOfUs />
    </>
  )
}

export const query = graphql`
  query {
    popup_banner: allMarkdownRemark(
      filter: {
        frontmatter: { featuredpopup: { eq: true } }
        fields: { sourceName: { eq: "popup-banner" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            button_text
            blurb
            expiration_date
            adtitle
            popup_image {
              childrenImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE)
              }
            }
            button_link
            featuredpopup
          }
        }
      }
    }
    blog: allMarkdownRemark(
      filter: { frontmatter: { featured: { in: [true] } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            description
            slug
            title
            categories
            featured_image {
              childrenImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE)
              }
            }
          }
          rawMarkdownBody
        }
      }
    }
    ads: allMarkdownRemark(
      filter: {
        frontmatter: {
          ad: { eq: true }
          title: { ne: "DUMMY AD (DO NOT DELETE)" }
        }
      }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            blurb
            description
            ad
            modalbuttontext
            modalbuttonlink
            videolink
            title
            slug
            expiration_date
            featured_image {
              childrenImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE)
              }
            }
            dates {
              displayformat
              seasondates {
                seasonend
                seasonstart
                seasonyearstart
                seasonyearend
              }
              monthdates {
                monthend
                monthstart
                yearend
                yearstart
              }
              specificdates {
                specificenddate(formatString: "MMM DD, YYYY")
                specificstartdate(formatString: "MMM DD, YYYY")
              }
            }
          }
          html
        }
      }
    }
  }
`

Index.Layout = DefaultLayout

export default Index
