import styled from "@emotion/styled"
import React, { useState } from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { PanelContainer } from "./panels/Panel-Container"
import { Button } from "./layout/StyledComponents"
import umbrellaGraphic from "../images/umbrella-graphic.svg"
import { Tooltip } from "./Tooltip"
import { Link } from "gatsby"

const StyledJustALabel = styled.section`
  padding: 72px 25px;
  .space {
    position: absolute;

    width: 100vw;
    left: 0;
    height: 120px;
  }
  h4 {
    font-weight: 900;
    line-height: 45px;
    letter-spacing: -1.25px;
    font-size: 40px;
    margin: 0em 0em 0em;
    color: ${colors.primary2};
    font-family: ${fonts.secondary};
  }

  p {
    font-size: 22px;
    color: ${colors.dark3};
  }
  .bottom-image {
    width: 100%;
  }
  .modal-button {
    font-family: ${fonts.primary};
    background: none;
    border: none;
    margin: 0em;
    font-size: 18px;
    padding: 0em;
    color: ${colors.primary2};
    font-weight: 700;
    text-decoration: underline;
  }
  .special {
    color: ${colors.primary2};
    font-weight: 700;
    text-decoration: underline;
  }
  @media ${device.tablet} {
    padding: 60px 0px 00px;
    article {
      margin-bottom: 8.5em;
      width: 33%;

      h4 {
        margin: 1em 0em 25px 0px;
        /* margin-bottom: 25px; */
        font-size: 50px;
        padding-right: 0em;
        line-height: 58px;
      }
      p {
        width: 84%;
      }
      button {
        margin-top: 20px;
      }
    }
    .bottom-image {
      width: 566px;
      height: 614px;
    }
    img {
      height: 614px;
      width: 566px;
    }
  }
  @media ${device.laptop} {
    padding: 22px 0px 0px;
    margin-top: 00px;
    margin-bottom: -20px;
  }
`

const tooltipText = [
  "Back, Indigenous, and People of Color",
  "The acronym stands for Lesbian, Gay, Bisexual, Transgender, Queer, Questioning, Asexual, and Allies.",
]

export const JustALabel = ({
  setToolTipMobile,
  toolTipMobile,
  hovered,
  setHovered,
}) => {
  const [name, setName] = useState("")
  const hoverAction = (bool, name) => {
    setName(name)
    setHovered(bool)
  }
  return (
    <StyledJustALabel>
      <PanelContainer style={{ position: "relative" }} reverse>
        <img
          onClick={() => setToolTipMobile(false)}
          className="bottom-image"
          src={umbrellaGraphic}
          alt="chart on stage-specific and community plans"
        />
        <article>
          <h4 onClick={() => setToolTipMobile(false)}>
            Our blended <br />
            Humani-TEA
          </h4>
          <p>
            Our programs are holistic and tailored-specific to those we serve to
            ensure we are not a one-size-fit-it-all organization. We are a home
            that welcomes, affirms, and honors the complexity of each person's
            story. We are religious and secular.
            {` `}
            {/*  <button className="modal-button">*/}{" "}
            <span
              onClick={() => setToolTipMobile(active => !active)}
              onMouseOver={() => hoverAction(true, "BIPOC")}
              onMouseLeave={() => hoverAction(false, "")}
            >
              {name === "BIPOC" ? (
                <Tooltip
                  buttonText="View full glossary"
                  toolTipMobile={toolTipMobile}
                  setToolTipMobile={setToolTipMobile}
                  text={tooltipText[0]}
                  hovered={hovered}
                  setHovered={setHovered}
                >
                  BIPOC
                </Tooltip>
              ) : (
                <span className="special">BIPOC</span>
              )}
            </span>{" "}
            and {/* </button>*/}White.{" "}
            <span
              onClick={() => setToolTipMobile(active => !active)}
              onMouseOver={() => hoverAction(true, "LGBTQ+")}
              onMouseLeave={() => hoverAction(false, "")}
            >
              {name === "LGBTQ+" ? (
                <Tooltip
                  buttonText="View full glossary"
                  toolTipMobile={toolTipMobile}
                  setToolTipMobile={setToolTipMobile}
                  text={tooltipText[1]}
                  hovered={hovered}
                  setHovered={setHovered}
                >
                  LGBTQ+
                </Tooltip>
              ) : (
                <span className="special">LGBTQ+</span>
              )}
            </span>{" "}
            and not.
          </p>
          <Link to="/programs/">
            <Button color="yellow">See how</Button>
          </Link>
          {/* <Link onClick={() => setToolTipMobile(false)} to="/purpose/#glossary">
            <Button color="yellow">View our glossary</Button>
          </Link> */}
        </article>
      </PanelContainer>
    </StyledJustALabel>
  )
}
