import styled from "@emotion/styled"
import React, { useState } from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "./layout/StyledComponents"
import { useInView } from "react-intersection-observer"
import { InView } from "react-intersection-observer"
import { Link } from "gatsby"
import { Fancybox } from "./Fancy-Box"

const StyledWhoWeAre = styled.div`
  display: flex;
  padding-bottom: 120px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: ${colors.primary1};
  article {
    position: relative;
    bottom: 22em;
    align-items: center;
    z-index: 18;
    display: flex;
    flex-direction: column;
  }
  p {
    color: ${colors.dark3};
  }
  h3 {
    font-size: 36px;
    margin: 0.25em;
    font-weight: 900;
    color: ${colors.white};
    font-family: ${fonts.secondary};
  }
  .bottom-text-container {
    p {
      font-weight: 400;
    }
    h3 {
      color: ${colors.primary2};
      line-height: 45px;
      letter-spacing: -1.25px;
      font-size: 40px;

      margin: 1.5em 0em 0.5em;
    }
    span {
      font-size: 22px;
      font-weight: 900;
      color: ${colors.dark3};
      letter-spacing: -0.24px;
      line-height: 36px;
      font-family: ${fonts.primary};
    }
    margin-top: -17em;
  }
  button {
    margin: 0 auto;
  }
  .main-image {
    display: flex;
    height: 308px;
    width: 100vw;
    position: relative;
    justify-content: center;
    bottom: 8em;
    align-items: center;
    .image-1 {
      z-index: 1;
      margin: 0em;
      left: 0px;
      position: absolute;
      img {
        width: 193.57px;
      }
    }
    .image-2 {
      left: 65px;
      z-index: 2;
      position: absolute;

      img {
        width: 239.68px;
      }
    }
    .image-3 {
      z-index: 3;
      position: absolute;
      right: 20px;
      img {
        width: 213.82px;
      }
    }
    .image-4 {
      z-index: 4;
      right: 0px;
      position: absolute;
      img {
        width: 143.57px;
      }
    }
  }
  @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
    background: ${colors.primary1};
    padding-bottom: 2em;
    article {
      margin-top: 12em;
      display: flex;
      flex-direction: column;
    }

    h3 {
      font-size: 52px;
    }
    .bottom-text-container {
      padding: 0em 0em 2em;
      h3 {
        font-size: 49px;
        color: ${colors.primary2};
      }
      margin-top: -32em;
    }
    .main-image {
      position: relative;
      top: -8em;
    }
  }
  @media ${device.tablet} and (orientation: portrait) {
    .bottom-text-container {
      margin-top: -32em;
    }
    .main-image {
      position: relative;
      bottom: 17em;
    }
    button {
      margin: 0 auto;
      /* max-width: 300px; */
    }
    h3 {
      font-size: 80px;
    }

    article {
      position: relative;
      bottom: 39em;
      display: flex;
      flex-direction: column;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    .invisible {
      opacity: 0;
    }
    .fade-1 {
      /* animation: fade-1 1s ease-in; */
      animation: fade 0.35s ease-in;
    }
    .fade-2 {
      animation: fade 0.7s ease-in;
    }
    .fade-3 {
      animation: fade 1.05s ease-in;
    }
    .fade-4 {
      animation: fade 1.4s ease-in;
    }
    .fade-5 {
      animation: fade 1.5s ease-in;
    }
    @keyframes fade {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    .bottom-text-container {
      margin-top: -33.5em;
    }
    .main-image {
      height: 508px;
      position: relative;
      bottom: 17em;
      .image-1 {
        z-index: 1;
        margin: 0em;
        left: 15px;
        position: absolute;
        img {
          width: 343.57px;
        }
      }
      .image-2 {
        left: 205px;
        z-index: 2;
        position: absolute;
        img {
          height: 508px;
          width: 479.68px;
        }
      }
      .image-3 {
        z-index: 3;
        position: absolute;
        right: 220px;
        img {
          width: 413.82px;
        }
      }
      .image-4 {
        z-index: 4;
        right: 15px;
        position: absolute;
        img {
          height: 364.27px;
          width: 343.57px;
        }
      }
    }
    .first-button {
      margin: 0 auto;
      padding: 15px 75px;
      max-width: 350px;
    }
    h3 {
      margin-bottom: 0px;
      font-size: 80px;
    }
    article {
      position: relative;
      bottom: 39em;
      display: flex;
      flex-direction: column;
    }
  }
  @media ${device.laptop} {
    padding: 0px 0px 128px;

    article {
      position: relative;
      bottom: 37em;
      display: flex;
      flex-direction: column;
    }
    .main-image {
      width: 1120px;
      position: relative;
      bottom: 14em;
    }
    .bottom-text-container {
      display: flex;
      padding-top: 128px;
      h3 {
        line-height: 50px;
        font-size: 50px;
      }
      span {
        font-size: 30px;
        font-weight: 900;
        letter-spacing: -0.94px;
        line-height: 36px;
      }
      div:last-of-type {
        margin-top: 3em;
        max-width: 640px;
        position: relative;
        p {
          font-size: 22px;
          letter-spacing: -0.69px;
          line-height: 36px;
        }
        button {
          margin-left: 390px;
        }
      }
    }
  }
`

export const WhoWeAre = () => {
  const [animation, setAnimation] = useState(false)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  })
  return (
    <StyledWhoWeAre>
      <InView
        onChange={(inView, entry) => {
          inView && setAnimation(true)
        }}
      >
        {({ inView, ref, entry }) => (
          <>
            <div className="main-image">
              <StaticImage
                className={`${animation ? "fade-1" : "invisible"} image-1`}
                src="../images/who-we-are-1.png"
                placeholder="none"
                quality={100}
                alt="mwangi smiling with flowers"
              />
              <StaticImage
                className={`${animation ? "fade-2" : "invisible"} image-2`}
                src="../images/who-we-are-2.png"
                placeholder="none"
                quality={100}
                alt="man and woman reading together"
              />
              <StaticImage
                className={`${animation ? "fade-3" : "invisible"} image-3`}
                src="../images/who-we-are-3.png"
                placeholder="none"
                quality={100}
                alt="woman jumping up"
              />
              <StaticImage
                className={`${animation ? "fade-4" : "invisible"} image-4`}
                src="../images/who-we-are-4.png"
                placeholder="none"
                quality={100}
                alt="mwangi with a student"
              />
            </div>
            <article className={`${animation ? "fade-5" : "invisible"}`}>
              <h3>Who are we?</h3>
              {/* <a
                href="https://vimeo.com/716460705"
                target="_blank"
                rel="noopener noreferrer"
              > */}
              <Fancybox options={{ infinite: false }}>
                <Button
                  data-fancybox="gallery"
                  data-src="https://vimeo.com/716460705"
                  className="first-button"
                  ref={ref}
                  backgroundFill
                  color="yellow"
                >
                  Watch our story
                </Button>
              </Fancybox>
              {/* </a> */}
            </article>
          </>
        )}
      </InView>
      <div className="container bottom-text-container">
        <div>
          <h3>A people-led philanthropy. </h3>
          <span>
            Small fills. <br />
            Countless impact.
          </span>
        </div>
        <div>
          <p>
            65% of our donors are alums of our programs. They give from a sense
            of duty to fellow humans rather than charity. 95% of all our donors
            are regular people making less than $60,000 a year who believe
            regular giving creates a better world as a natural consequence of
            our daily actions rather than altruism.
          </p>
          <Link to="/donate/">
            <Button color="yellow">Support us today!</Button>
          </Link>
        </div>
      </div>
    </StyledWhoWeAre>
  )
}
