import styled from "@emotion/styled"
import React, { useState } from "react"
import { colors, fonts, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"
// import { Button } from "../layout/StyledComponents"
import { ModalBlock } from "../Modal"
import { modalText } from "../modalText"
import { Button } from "../layout/StyledComponents"
import { Link } from "gatsby"
import { Tooltip } from "../Tooltip"
const StyledNurturePanel = styled.section`
  background: ${colors.primary2};
  color: ${colors.white};
  padding: 1px 0em 117px;
  position: relative;
  bottom: 1px;

  .bottom-image {
    
    display: none;
  }
  p {
    color: ${colors.white};
    font-size: 22px;
    margin-bottom: 32px;
  }
  .bold-text {
    letter-spacing: -0.69px;
    line-height: 32px;
    font-weight: 900;
    font-size: 22px;
    font-family: ${fonts.primary};
  }
  button {
    margin: 2.5em 0em 3.5em;
  }
  h2 {
    color: ${colors.white};
    line-height: 45px;
    letter-spacing: -1.25px;
    font-family: ${fonts.secondary};
    font-size: 40px;
  }
  /* regular ipad */
  @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
    margin-top: -4.2em;
    button {
      margin: 0em 0em 4em 2em;
    }
    .bold-text {
      margin: 0;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    padding: 0px;
    margin-top: -4.2em;
  }

  @media ${device.laptop} {
    .bottom-image {
      display: block;
    }
    /* test-container */
    padding: 47px 0em 328px;
    margin: -33px;
    h2 {
      font-weight: 800;
      margin-bottom: 10px;
    }
    .bold-text {
      display: block;
      font-weight: 900;

      letter-spacing: -0.69px;
      line-height: 36px;
      font-size: 22px;
    }
    p {
      font-weight: 400;
    }
    h2 + p {
      width: 480px;
    }
    button {
      margin: 1.5em 2em 0em 0em;
    }
    .top-image {
      width: 530px;
      position: relative;
      right: 3em;
      top: 15px;
    }
    .bottom-image {
      width: 550px;
      position: relative;
      right: 3em;
    }
    > section {
      margin-top: 2em;
    }
    > section article {
      width: 470px;
    }
    .first-article {
      margin-bottom: 20px;

      p {
        width: 400px;
        padding-right: 100px;
      }
    }
    .second-article {
      margin-bottom: 60px;
      width: 500px;
    }
    > section article:first-of-type {
    }

    h2 {
      letter-spacing: -1.56px;
      line-height: 55px;

      font-size: 50px;
    }
  }
`

const tooltipText = [
  "According to the World Bank, a child’s inability to read and understand a simple text by age 10.",
  "We define these as Individuals or families living on less than US $1.90 per person per day in 2011 purchasing power parity (PPP) in the world's poorest countries. In lower-and upper-middle-income countries, $3.20 and $5.50 per person per day, respectively.",
]

export const NurturePanel = ({
  setToolTipMobile,
  toolTipMobile,
  hovered,
  setHovered,
}) => {
  const [name, setName] = useState("")
  const hoverAction = (bool, name) => {
    setName(name)
    setHovered(bool)
  }
  return (
    <StyledNurturePanel>
      <PanelContainer>
        <article className="first-article">
          <h2>We nurture Possibili-TEAs.</h2>
          <p>
            We are a faith-based organization like no other. Community-driven
            and grassroots-led. Justice, equity, diversity, and inclusion (JEDI)
            are at the heart of our work. In everything we do, we ensure equity
            of voice and a place to belong.
          </p>
        </article>
        <StaticImage
          className="top-image"
          src="../../images/panels/nurture-collage.png"
          placeholder="none"
          quality={100}
          alt="crowd and two people with plant"
        />
      </PanelContainer>
      <PanelContainer reverse>
        <article className="second-article">
          <p>
            Founded and led by survivors of persecution and poverty, we support
            children experiencing{" "}
            <span
              onClick={() => setToolTipMobile(active => !active)}
              onMouseOver={() => hoverAction(true, "learning")}
              onMouseLeave={() => hoverAction(false, "")}
            >
              {name === "learning" ? (
                <Tooltip
                  inverse
                  buttonText="View full glossary"
                  toolTipMobile={toolTipMobile}
                  setToolTipMobile={setToolTipMobile}
                  text={tooltipText[0]}
                  hovered={hovered}
                  setHovered={setHovered}
                >
                  <span>learning poverty</span>
                </Tooltip>
              ) : (
                <span
                  style={{ color: `${colors.secondary202}` }}
                  className="x special"
                >
                  learning poverty
                </span>
              )}
            </span>{" "}
            to read and write, mentor NextGen leaders to innovate and thrive and
            uplift families{" "}
            <span
              onClick={() => setToolTipMobile(active => !active)}
              onMouseOver={() => hoverAction(true, "margins")}
              onMouseLeave={() => hoverAction(false, "")}
            >
              {name === "margins" ? (
                <Tooltip
                  inverse
                  buttonText="View full glossary"
                  toolTipMobile={toolTipMobile}
                  setToolTipMobile={setToolTipMobile}
                  text={tooltipText[1]}
                  hovered={hovered}
                  setHovered={setHovered}
                >
                  <span>living on the margins</span>
                </Tooltip>
              ) : (
                <span
                  style={{ display: "inline", color: `${colors.secondary202}` }}
                  className="special"
                >
                  living on the margins
                </span>
              )}
            </span>{" "}
            to meet their basic needs.
          </p>

          {/* old -<span>We call it spirituali-TEA</span> --->take away :block from span css */}
          <span className="bold-text">
            We call our unique theory of change, Spirituali-TEA.{" "}
          </span>
          <Link to="/purpose">
            <Button color="yellow">See how</Button>
          </Link>
          {/*old -> led to modal before}
          {/* <ModalBlock modalText={modalText.sdg.text} color="yellow">
            See why we rise
          </ModalBlock> */}
        </article>
        <StaticImage
          className="bottom-image"
          src="../../images/panels/founded-collage.png"
          placeholder="none"
          quality={100}
          alt="woman dancing and two people posing"
        />
      </PanelContainer>
    </StyledNurturePanel>
  )
}
