import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"
import animatedPeople from "../images/test-images/figma-export.svg"
import { modalText } from "./modalText"
import { ModalBlock } from "./Modal"

const StyledGivingLegacy = styled.div`
  background: ${colors.primary2};
  padding: 72px 0em;
  .image {
    display: none;
  }

  .first-button {
    margin: 0em 0em 2em;
  }
  section:last-of-type {
    margin-top: 16px;
  }
  section {
    p:first-of-type {
      font-size: 40px;
      letter-spacing: -1.25px;
      line-height: 28px;
      margin: 0em 0em 0.25em;
      font-weight: 900;
      font-family: ${fonts.secondary};
    }
    p:last-of-type {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
      line-height: 36px;
      font-family: ${fonts.primary};
    }
    p,
    a {
      color: ${colors.white};
    }
  }
  @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
    padding: 128px 0em;
    .image {
      display: block;
      margin: 72px auto 0;
      width: 92%;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    padding: 148px 0px 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    section:last-of-type {
      margin-top: 0px;
    }
    .image {
      display: block;
      display: block;
      margin: 75px auto 0;
      width: auto;
    }
    .flex-container {
      display: flex;
    }
    section {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin: 0px 0em 00px;
      width: 550px;
      p:first-of-type {
        text-align: center;
        padding: 0em;
        line-height: 27px;
        margin: 0em 0em;
      }
      p {
        text-align: center;
        padding: 0em;
        width: 430px;
        margin: 0.5em 0em 1.5em;
      }
    }
    div:last-of-type {
      width: 1150px;
      height: 154px;
      padding: 0em;
      margin: -0.75em 0em 0em;
    }
  }
`

export const GivingLegacy = () => {
  return (
    <>
      <StyledGivingLegacy>
        <div className="container flex-container">
          <section>
            <p>Planned Giving</p>
            <p>Receive a tax deduction when you make a gift of stock.</p>
            <button
              style={{ border: "none", background: "none" }}
              className="first-button"
            >
              <ModalBlock modalText={modalText.giving.text}>
                <Button color="yellow">Get the details</Button>
              </ModalBlock>
            </button>
          </section>
          <section className="">
            <p>Leave a Legacy</p>
            <p>Name MLIFE as your beneficiary charity in your Estate Plan.</p>
            <button style={{ border: "none", background: "none" }}>
              <ModalBlock modalText={modalText.legacy.text}>
                <Button color="yellow">Get the details</Button>
              </ModalBlock>
            </button>
          </section>
        </div>
        <img
          className="image"
          src={animatedPeople}
          alt="animated community of poeple"
        />
      </StyledGivingLegacy>
    </>
  )
}
