import styled from "@emotion/styled"
import React, { useEffect } from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import Modal from "react-modal"
import { Button } from "./layout/StyledComponents"
import closeRed from "../images/close-red-new.svg"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"

const MainContainer = styled.div`
  background: #fffaf1;
  opacity: 1;

  .line {
    height: 100px;
    width: 1px;
    background: black;
  }
  h1,
  h2,
  h3 {
    font-weight: 400;
    font-size: 32px;
    letter-spacing: -1.25px;
    line-height: 38px;
    margin: 32px 0 15px 0px;
  }
  ul,
  ol {
    color: ${colors.dark3};
    li {
      font-size: 22px;
      font-family: ${fonts.primary};
      margin: 6px 0px;
      letter-spacing: -0.69px;
      color: ${colors.dark3};
      line-height: 36px;
    }
    margin: 14px 0px;
    text-align: left;
    padding: 0 0 0 32px;
  }
  a {
    font-weight: 600;
    text-decoration: none;
    color: ${colors.primary2};
  }
  a:hover {
    text-decoration: underline;
  }
  .logo-container {
    img {
      width: 90px;
    }
    .rapid-logo {
      width: 70px;
      position: relative;
      left: 15px;
    }
    .spaces-logo {
      margin-right: -50px;
      width: 130px;
    }
    display: flex;
    margin: 60px 0px;
    width: 300px;
    justify-content: space-between;
  }
  .main-logo-container {
    display: flex;
    margin: 30px 0px;
    align-items: center;
    width: 280px;
    justify-content: space-between;
    .mlife-text {
      border-bottom: 1px solid red;
    }
    div {
      width: 1px;
      height: 80px;
      position: relative;
      top: 00px;
      background: black;
    }
    .sdg-logo {
      width: 120px;
    }
    .logo {
      width: 120.3px;
    }
  }
  padding: 128px 32px 72px;
  position: relative;
  .main-logo-container {
  }
  .close-top {
    cursor: pointer;
    position: absolute;
    right: 25px;
    z-index: 200000000000;
    top: 25px;
  }
  .close-bottom {
    cursor: pointer;
    position: absolute;
    right: 25px;
    bottom: 35px;
  }
  p {
    font-family: ${fonts.primary};
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    color: ${colors.dark3};
    line-height: 36px;

    margin: 0;
  }
  h5 {
    font-family: ${fonts.secondary};
    font-size: 40px;
    color: ${colors.primary2};
    font-weight: 800;
    margin: 0 0 16px 0px;
    letter-spacing: -1.88px;
    line-height: 48px;
  }
  h6 {
    color: ${colors.dark3};
    font-family: ${fonts.primary};
    font-weight: 900;
    font-size: 30px;
    margin: 48px 0 16px 0;
  }
  .contact {
    p:first-of-type {
      margin-top: 32px;
      font-weight: bold;
    }
    a {
      color: ${colors.primary2};
      font-size: 22px;
      font-family: ${fonts.primary};
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .overlay-image {
    margin-bottom: 28px;
  }
  .spec {
    margin-top: 16px;
  }
  .top {
    padding-top: 32px;
  }
  .date-text {
    margin-top: 32px;
  }
  .date {
    border-bottom: 1px solid #861b1f;
    padding-bottom: 32px;
    h6 {
      font-family: ${fonts.primary};
      font-size: 30px;
      font-weight: 900;
      letter-spacing: -0.94px;
      line-height: 36px;
    }
    p:first-of-type {
      padding-top: 32px;
    }
    p:last-of-type {
      padding-top: 5px;
    }
    p {
      font-family: ${fonts.primary};
      font-size: 22px;
      font-weight: 900;
      letter-spacing: -0.69px;
      line-height: 36px;
    }
  }
  @media ${device.tablet} {
    .line {
      height: 100px;
      width: 1px;
      background: black;
    }

    .logo-container {
      img {
        width: 90px;
      }
      .rapid-logo {
        width: 70px;
        position: relative;
        left: 15px;
      }
      .spaces-logo {
        margin-right: -50px;
        width: 130px;
      }
      display: flex;
      margin: 60px 0px;
      width: 360px;
      justify-content: space-between;
    }

    .main-logo-container {
      display: flex;
      margin: 30px 0px;
      align-items: center;
      width: 330px;
      justify-content: space-between;
      .mlife-text {
        border-bottom: 1px solid red;
      }
      div {
        width: 1px;
        height: 80px;
        position: relative;
        top: 00px;
        background: black;
      }
      .sdg-logo {
        width: 140px;
      }
      .logo {
        width: 140.3px;
      }
    }
    .date {
      border-bottom: 1px solid #861b1f;
      padding-bottom: 32px;
      p:first-of-type {
        padding-top: 64px;
        font-size: 28px;
      }
      p:last-of-type {
        padding-top: 5px;
      }
    }
    .date-text {
      margin-top: 64px;
    }
    .close-top {
      position: absolute;
      right: 89px;
      top: 30px;
    }
    .close-bottom {
      position: absolute;
      right: 49px;
      bottom: 50px;
    }
    h5 {
      font-family: ${fonts.secondary};
      font-size: 60px;
      line-height: 65px;
    }
  }
  @media ${device.laptop} {
    height: fit-content;
    width: 958px;
    border-radius: 8px;
    margin: 0 auto;
    position: relative;
    opacity: 1;
    .overlay-image {
      position: absolute;
      left: -80px;
      height: 428px;
      width: 447px;
      top: 140px;
      border-radius: 5px;
    }
    .copy {
      position: relative;
      margin-left: 454px;
      width: 400px;
    }
    h1 {
      margin-right: 120px;
    }
    h2,
    h3,
    h4,
    h5 {
      font-size: 50px;
      font-weight: 800;
      letter-spacing: -1.56px;
      line-height: 55px;
    }
    p {
      margin-right: 40px;
    }

    .close-top {
      position: absolute;
      right: 45px;
      top: 47px;
    }
    .close-bottom {
      position: absolute;
      right: -05px;
      bottom: 64px;
    }
  }
  @media ${device.desktop} {
  }
`

const StyledModal = styled(Modal)`
  /* top: 400px;
  bottom: 0px; */
  overflow-y: scroll;
  background: rgba(22, 103, 90, 0.8);
  opacity: 0.8;
  box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.1);
  left: 50%;
  height: 100vh;
  padding: 00px 0px 60px;
  @media ${device.laptop} {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  @media ${device.laptopL} {
  }
  @media ${device.desktop} {
    bottom: 0px;
    min-width: 100vw;
  }
  @media only screen and (min-width: 2100px) {
    padding-top: 200px;
  }
`

const customStyles = {
  content: {
    opacity: "1",
  },
  overlay: {
    zIndex: "2001",
  },
}

export const HomeModal = ({ children, modalContent }) => {
  let subtitle
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const { adtitle, blurb, button_text, button_link, popup_image } = modalContent

  function closeModal() {
    setIsOpen(false)
  }

  useEffect(() => {
    setIsOpen(true)
  }, [])

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    return
  }

  return (
    <>
      <StyledModal
        className="modal"
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <MainContainer>
          <GatsbyImage
            className="overlay-image"
            image={getImage(popup_image.childrenImageSharp[0])}
          />
          <img
            onClick={closeModal}
            className="close-top"
            src={closeRed}
            alt="close modal"
          />
          <div className="copy">
            <h5>{adtitle}</h5>
            <p>{blurb}</p>
            {button_link && (
              <a target="_blank" rel="noreferrer" href={button_link}>
                <Button style={{ marginTop: "32px" }} color="yellow">
                  {button_text}
                </Button>
              </a>
            )}
          </div>
        </MainContainer>
      </StyledModal>
    </>
  )
}
