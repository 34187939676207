import React, { useEffect, useMemo, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { device, colors, fonts } from "./layout/GlobalStyles"

const OutterContainer = styled.div`
  position: relative;
  padding: 64px 0px;

  @media ${device.laptop} {
    padding: 107px 0px;

    a {
      transition: opacity 0.3s ease-in-out;
      &:hover {
        opacity: 0.6;
      }
    }
  }
`

const TextBlock = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -48px;
  width: 100vw;
  h3 {
    border-radius: 10px;
    margin: 0 auto;
    text-align: center;
    padding: 16px 32px;
    background: ${colors.white};
    font-size: 36px;
    font-weight: 900;
    color: ${colors.primary2};
    font-family: ${fonts.secondary};
  }
  @media ${device.laptop} {
    top: -72px;
    h3 {
      padding: 48px 120px;
      font-size: 50px;
      font-weight: 800;
      letter-spacing: -1.56px;
      line-height: 55px;
      text-align: center;
    }
  }
`

const Logos = styled.div`
  display: flex;
  gap: 72px;
  position: relative;
  overflow-x: hidden;
  @media ${device.laptop} {
    gap: 154px;
  }
`

const LogosSlide = styled.div`
  flex-shrink: 0;
  gap: 72px;

  display: flex;
  @keyframes slide {
    from {
      transform: translateX(00%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  animation: slide 88s linear infinite;
  div {
    flex-shrink: 0;
  }
  @media ${device.laptop} {
    gap: 154px;
  }
`

export const ImageCarousel = () => {
  const ref = useRef(null)
  const imageRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  const callbackFunction = entries => {
    const [entry] = entries
    setIsVisible(entry.isIntersecting)
  }

  const options = useMemo(() => {
    //each time the component re renders it will create a new object
    //with usememo it will only create this obj once in memory
    return {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    const currentTarget = imageRef.current
    if (currentTarget) observer.observe(currentTarget)
    return () => {
      if (currentTarget) observer.unobserve(currentTarget)
    }
  }, [imageRef, options])
  const data = useStaticQuery(graphql`
    query Images {
      allMarkdownRemark(
        filter: { fields: { sourceName: { eq: "image-carousel" } } }
      ) {
        edges {
          node {
            fields {
              sourceName
            }
            frontmatter {
              images {
                imagefile {
                  childrenImageSharp {
                    gatsbyImageData(height: 45, placeholder: NONE)
                  }
                  name
                }
                imagelink
                imagename
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allMarkdownRemark.edges[0].node.frontmatter.images
  return (
    <OutterContainer>
      <TextBlock>
        <h3 class="sideways-border">As seen in</h3>
      </TextBlock>
      <Logos ref={imageRef}>
        {isVisible && (
          <>
            <LogosSlide slide={isVisible} ref={ref}>
              {images.map(image => {
                const { imagename, imagefile, imagelink } = image
                const imageData = getImage(imagefile.childrenImageSharp[0])
                if (imagelink) {
                  return (
                    <a href={imagelink} target="_blank" rel="noreferrer">
                      <GatsbyImage
                        key={imagename}
                        image={imageData}
                        alt={imagename}
                      />
                    </a>
                  )
                } else {
                  return (
                    <GatsbyImage
                      key={imagename}
                      image={imageData}
                      alt={imagename}
                    />
                  )
                }
              })}
            </LogosSlide>
            <LogosSlide>
              {images.map(image => {
                const { imagename, imagefile, imagelink } = image
                const imageData = getImage(imagefile.childrenImageSharp[0])
                if (imagelink) {
                  return (
                    <a href={imagelink} target="_blank" rel="noreferrer">
                      <GatsbyImage
                        key={imagename}
                        image={imageData}
                        alt={imagename}
                      />
                    </a>
                  )
                } else {
                  return (
                    <GatsbyImage
                      key={imagename}
                      image={imageData}
                      alt={imagename}
                    />
                  )
                }
              })}
            </LogosSlide>
            <LogosSlide>
              {images.map(image => {
                const { imagename, imagefile, imagelink } = image
                const imageData = getImage(imagefile.childrenImageSharp[0])
                if (imagelink) {
                  return (
                    <a href={imagelink} target="_blank" rel="noreferrer">
                      <GatsbyImage
                        key={imagename}
                        image={imageData}
                        alt={imagename}
                      />
                    </a>
                  )
                } else {
                  return (
                    <GatsbyImage
                      key={imagename}
                      image={imageData}
                      alt={imagename}
                    />
                  )
                }
              })}
            </LogosSlide>
          </>
        )}
      </Logos>
    </OutterContainer>
  )
}
