import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"

const StyledWhatWeveDone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f1f3fc;
  p {
    color: ${colors.white};
  }
  h4 {
    color: ${colors.primary2};
    font-family: ${fonts.secondary};
    font-size: 36px;
    margin-bottom: 0.5em;
  }

  h4 + span {
    font-family: ${fonts.primary};
    color: ${colors.dark3};
    font-weight: 900;
    font-size: 24px;
    text-align: center;
  }
  .grid-container {
    display: grid;
    padding: 4em 0em 2em;
    align-self: center;
    grid-auto-rows: minmax(290px, auto);
    grid-template-columns: repeat(1, 320px);
    p {
      color: ${colors.dark3};
    }
    .box-1 {
      border-bottom: 1px solid #3e7ab1;
    }
    .box-2 {
      border-bottom: 1px solid #3e7ab1;
    }
    .box-3 {
      border-bottom: 1px solid #3e7ab1;
    }
    .box-4 {
      border-bottom: 1px solid #3e7ab1;
    }
    .box-5 {
      border-bottom: 1px solid #3e7ab1;
    }
    .box-6 {
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0.5em;
      /* green text */
      p:first-of-type {
        font-weight: 900;
        color: ${colors.primary2};
        font-size: 36px;
        font-family: ${fonts.secondary};
        margin: 1em 0em 0.5em;
      }
      /* middle text */
      span {
        font-size: 28px;
        font-family: ${fonts.secondary};
        font-weight: 900;
        color: ${colors.primary2};
      }
      p:last-of-type {
        font-family: ${fonts.primary};
      }
      p {
        text-align: center;
        font-size: 22px;
      }
    }
  }
  @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
    .grid-container {
      display: grid;
      padding: 0em 0em 2em;
      align-self: center;
      grid-auto-rows: minmax(290px, auto);
      grid-template-columns: repeat(1, 320px);
      .box-2,
      .box-3,
      .box-4,
      .box-5 {
        border-bottom: 1px solid #3e7ab1;
      }
      .box-1,
      .box-2 {
        border-left: 0px solid #3e7ab1;
      }
      .box-2 {
        border-right: 0px solid #3e7ab1;
      }
      .box-3 {
        border-left: 0px solid #3e7ab1;
      }
      .box-4 {
        border-left: 0px solid #3e7ab1;
        border-right: 0px solid #3e7ab1;
      }
      .box-5 {
        border-left: 0px solid #3e7ab1;
        border-left: 0px solid #3e7ab1;
      }
      .box-6 {
        border-left: 0px solid #3e7ab1;
        border-right: 0px solid #3e7ab1;
      }
      .box-5,
      .box-6 {
        border-bottom: 0px solid #3e7ab1;
      }
    }
  }
  @media ${device.laptop} and (orientation: landscape) {
    padding-top: 128px;
    .grid-container {
      display: grid;
      padding: 0em 0em 2em;
      align-self: center;
      grid-auto-rows: minmax(290px, auto);
      grid-template-columns: repeat(2, 320px);
    }
    .box-2,
    .box-1,
    .box-3,
    .box-4,
    .box-5 {
      border-bottom: 1px solid #3e7ab1;
    }
    h4 {
      font-weight: 300;
      letter-spacing: -2.5px;
      line-height: 55px;
      font-size: 80px;
      margin: 0em 0em 20px;
    }
    h4 + span {
      font-size: 30px;
    }
    .box-1 p:last-of-type {
      width: 65%;
    }
    .grid-container {
      width: 1200px;
      div {
        min-width: 360px;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 1em;
        p:first-of-type {
          margin-bottom: 0.2em;
          font-size: 58px;
        }
        span {
          margin-bottom: -0.5em;
          font-size: 27px;
        }
        p {
          text-align: left;
          max-width: 260px;
        }
      }
      display: grid;
      padding: 3em 0em 4em;
      align-self: center;
      grid-auto-rows: minmax(320px, auto);
      grid-template-columns: repeat(3, 330px);
      .box-1 {
        border-bottom: 1px solid #3e7ab1;
        border-left: 1px solid #3e7ab1;

        border-right: 1px solid #3e7ab1;
      }
      .box-2 {
        border-left: 1px solid #3e7ab1;

        border-right: 1px solid #3e7ab1;
      }
      .box-3 {
        border-left: 1px solid #3e7ab1;

        border-right: 1px solid #3e7ab1;
      }
      .box-4 {
        border-left: 1px solid #3e7ab1;

        border-right: 1px solid #3e7ab1;
      }
      .box-5 {
        border-left: 1px solid #3e7ab1;

        border-right: 1px solid #3e7ab1;
      }
      .box-6 {
        border-left: 1px solid #3e7ab1;
        border-bottom: 0px solid #3e7ab1;
        border-right: 1px solid #3e7ab1;
      }
      .box-1,
      .box-2,
      .box-3 {
        p:first-of-type {
          margin-top: 0em;
        }
      }
      .box-1,
      .box-4 {
        border-right: 0px solid #3e7ab1;
        border-left: 0px solid #3e7ab1;
      }
      .box-3,
      .box-6 {
        border-left: 0px solid #3e7ab1;
        border-right: 0px solid #3e7ab1;
        padding-left: 12em;
      }
      .box-2,
      .box-5 {
        padding-left: 5em;
      }
      .box-4,
      .box-5,
      .box-6 {
        border-bottom: 0px solid #3e7ab1;
      }
    }
  }
  .bottom-image {
    width: 100vw;
  }
  @media ${device.desktop} {
    .bottom-image {
      height: 1200px;
      width: 100vw;
    }
  }
`

const BlueContainer = styled.div`
  background: ${colors.secondary102};
  color: ${colors.white};
  margin: 0em;
  padding: 25px 25px 45px;
  p:first-of-type {
    padding: 0px;
    font-weight: 300;
    letter-spacing: -1.56px;
    line-height: 47px;
    text-align: center;
    font-size: 32px;
    font-family: ${fonts.secondary};
  }
  p:last-of-type {
    line-height: 47px;
    text-align: center;
    font-size: 22px;
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    padding: 108px 0px 85px;
    p:first-of-type {
      margin: 0 auto;
      font-size: 50px;
      padding: 0px 320px;
      line-height: 55px;
      font-weight: 300;
      letter-spacing: -1.56px;
      line-height: 55px;
    }
    p:last-of-type {
      font-size: 30px;
    }
  }
`

export const WhatWeveDone = () => {
  return (
    <>
      <StyledWhatWeveDone>
        <h4>What we've done</h4>
        <span>We met, connected, and served</span>
        <div className="container grid-container">
          <div className="box-1">
            <p>500+</p>
            <span>families</span>
            <p>supplied with water and basic needs</p>
          </div>
          <div className="box-2">
            <p>200</p>
            <span>students</span>
            <p>
              and their families supported with direct cash assistance during
              COVID-19
            </p>
          </div>
          <div className="box-3">
            <p>2,010</p>
            <span>students served</span>
            <p>
              students served through Learning Enrichment & Acceleration Program
              (LEAP)
            </p>
          </div>
          <div className="box-4">
            {" "}
            <p>300+</p>
            <span>leaders</span>
            <p>
              leaders empowered through training in entrepreneurship and job
              placement services.
            </p>
          </div>
          <div className="box-5">
            {" "}
            <p>150+</p>
            <span>Spaces of Grace</span>
            <p>
              Spaces of Grace conversations convened to guide communities
              through difficult times.
            </p>
          </div>
          <div className="box-6">
            {" "}
            <p>10</p>
            <span>financial grants</span>
            <p>to partner organizations.</p>
          </div>
        </div>
        <StaticImage
          className="bottom-image"
          src="../images/kids-group.jpeg"
          placeholder="none"
          quality={100}
          alt="group of students smiling"
        />
        <BlueContainer>
          <p>
            “We are not human beings having a spiritual experience. We are
            spiritual beings having a human experience."
          </p>
          <p>Pierre Teilhard de Chardin, S.J. </p>
        </BlueContainer>
      </StyledWhatWeveDone>
    </>
  )
}
