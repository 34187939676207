import styled from "@emotion/styled"
import React from "react"
import { Link } from "gatsby"
import { colors, fonts, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "../layout/StyledComponents"
import { PanelContainer } from "./Panel-Container"

const StyledDonatePanel = styled.section`
  background: ${colors.primary1};
  padding: 72px 0em 0px;
  h4 + p {
    margin: 28px 0;
  }
  p {
    font-size: 22px;

    color: ${colors.dark3};
  }
  p {
    margin-bottom: 2em;
  }
  button {
    margin: 2.5em 0em 3.5em;
  }
  h4 {
    color: ${colors.primary2};
    line-height: 45px;
    margin: 1em 0em 0.25em;
    letter-spacing: -1.25px;
    font-family: ${fonts.secondary};
    font-size: 40px;
  }
  .sub-text {
    button {
      width: 230px;
      position: relative;
      bottom: 1em;
    }
    p {
      font-size: 12px;
      letter-spacing: -0.38px;
      line-height: 16px;
      font-weight: 400;
      padding-right: 0.5em;
    }
    span {
      font-weight: 900;
    }
  }
  /* regular ipad */
  @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
    padding: 69px 0em 0;
    margin-top: -4.2em;
    .sub-text {
      display: flex;
      button {
        margin-left: 3em;
      }
    }
    button {
      margin-top: 1.5em;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    padding: 74px 4em 0px;
  }

  @media ${device.laptop} {
    /* test-container */
    padding: 82px 0em;
    .sub-text {
      display: flex;
      align-items: center;
      p {
      }
    }

    button {
      margin: 1.5em 1em 0em 1.4em;
      max-width: 214px;
      position: relative;
      left: 0em;
    }
    .image {
      width: 580px;
      position: relative;
      right: 3em;
    }
    > section {
      margin-top: 0em;
    }
    > section article {
      width: 50%;
    }
    h4 {
      margin: 0.5em 0em 0em;
      font-weight: 900;
      letter-spacing: -1.56px;
      line-height: 55px;
      font-size: 50px;
    }
  }
`

export const DonatePanel = () => {
  return (
    <StyledDonatePanel>
      <PanelContainer>
        <StaticImage
          className="image"
          src="../../images/panels/donate-panel.png"
          placeholder="none"
          quality={100}
          alt="woman dancing and two people posing"
        />
        <article>
          <h4>
            Support us <br />
            BE TEA-nacious.
          </h4>
          <p>
            Your donation today supports us in creating opportunities where none
            existed: education for children left behind, a community for those
            ostracized, and belief in those who scarcely believe they belong.
          </p>
          <div className="sub-text">
            <p>
              {/*<span>US citizens and residents.</span>*/} MLIFE is a US
              501(c)3 faith-based organization. EIN: 47-4678443. For US citizens
              and residents: To claim the tax deductibility of this gift in the
              United States, please retain the acknowledgment receipt for your
              record.
            </p>
            <Link to="/donate/">
              <Button color="yellow">Donate today!</Button>
            </Link>
          </div>
        </article>
      </PanelContainer>
    </StyledDonatePanel>
  )
}
