import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Button } from "./layout/StyledComponents"
import { Link } from "gatsby"

const OutterContainer = styled.div`
  background: #f4e5aa;
`

const StyledBlogPanel = styled.div`
  padding: 72px 0px;

  .button {
    border: 3px solid #e3bf2a;
  }
  .buttons {
    margin-top: 32px;
  }
  .button:hover {
    border: 3px solid #672e31;
  }
  span {
    color: ${colors.dark3};
  }
  h3 {
    letter-spacing: -1.25px;
    line-height: 38px;
    font-weight: 700;
  }
  /* image */
  p {
    color: ${colors.dark3};
    font-size: 22px;
  }
  div:first-of-type {
  }
  .main-image {
    border-radius: 15px;
    img {
      border-radius: 15px;
    }
  }
  span {
    font-size: 22px;
    font-weight: 900;
    font-weight: 900;
    letter-spacing: -0.69px;
    line-height: 36px;
    font-family: ${fonts.primary};
  }
  h3 {
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    font-size: 32px;
    margin: 1em 0em 0.5em;
  }
  /* buttons */
  article div:last-of-type {
    display: flex;
    justify-content: space-between;
  }
  @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 128px 0px;

    h3 {
      line-height: 48px;
      font-weight: 500;
      font-size: 32px;
    }

    /* buttons */
    article div:last-of-type {
      max-width: 350px;
      margin: 2em 0em 0em;
      display: flex;

      justify-content: space-between;
    }
  }
  @media ${device.tablet} and (orientation: landscape) {
    display: flex;
    padding: 72px 0px 280px;
    flex-direction: row;

    align-items: start;
    .main-image {
      width: 391px;
      flex-shrink: 0;
      height: 380px;
      position: relative;
      bottom: 10px;
      right: 30px;
    }
    article {
      max-width: 550px;
      position: relative;
      bottom: 1em;
      margin: 0;

      margin-left: 24px;
    }
    h3 {
      line-height: 45px;
      padding: 0;
      margin: 0;
      margin-bottom: 10px;
      font-size: 40px;
    }
    /* buttons */
    article div:last-of-type {
      max-width: 460px;
      margin: 1.5em 0em 0em;
      display: flex;
      justify-content: space-between;
    }
    p + div {
      width: 370px;
    }
  }
  @media ${device.laptop} {
    padding: 128px 0px 85px;
    .main-image {
      flex-shrink: 1;
      right: 00px;
      width: 661px;
      height: 380px;
      position: relative;
      bottom: 10px;
    }
    article {
      margin-left: 96px;
    }
  }
`

export const BlogPanel = ({ blog: { edges } }) => {
  const featuredBlog = edges[0].node
  const { frontmatter } = featuredBlog
  const reformattedString = frontmatter.date.toString().slice(0, 10)

  const month = reformattedString.slice(5, 7)

  let day = reformattedString.slice(8)
  if (day[0] === "0") {
    day = day.slice(-1)
  }

  const year = reformattedString.slice(0, 4)
  let names = []
  const categoriesText = frontmatter.categories.map(name => {
    names.push(name)
    return names
  })

  let parsedNames = categoriesText[0].join(" • ")

  const monthRender = month => {
    if (month === "01") {
      return "Jan"
    }
    if (month === "02") {
      return "Feb"
    }
    if (month === "03") {
      return "Mar"
    }
    if (month === "04") {
      return "Apr"
    }
    if (month === "05") {
      return "May"
    }
    if (month === "06") {
      return "Jun"
    }
    if (month === "07") {
      return "Jul"
    }
    if (month === "08") {
      return "Aug"
    }
    if (month === "09") {
      return "Sep"
    }
    if (month === "10") {
      return "Oct"
    }
    if (month === "11") {
      return "Nov"
    }
    if (month === "12") {
      return "Dec"
    }
  }
  return (
    <OutterContainer>
      <StyledBlogPanel className="container">
        <GatsbyImage
          className="main-image"
          image={getImage(frontmatter.featured_image.childrenImageSharp[0])}
        />
        <article>
          <h3>{frontmatter.title}</h3>
          <span>
            {" "}
            {`${monthRender(month)} ${day}, ${year}`} | {parsedNames}
          </span>
          <p>{frontmatter.description}</p>
          <div className="buttons">
            <Link to={`/news-events/${frontmatter.slug}`}>
              <Button className="button" color="yellow">
                Read article
              </Button>
            </Link>
            <Link to={`/news-events/`}>
              <Button color="clear">View blog</Button>
            </Link>
          </div>
        </article>
      </StyledBlogPanel>
    </OutterContainer>
  )
}
